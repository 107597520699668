import { createSignal, Show, splitProps } from "solid-js";

import type { CheckboxRgpd } from "~/components/Forms/Fields/Base/InputTypesProps";
import { InputTypeCheckbox } from "~/components/Forms/Fields/Base/InputTypeCheckbox";
import { optional, picklist } from "valibot";
import IconExpandMore from "~/img/icons/expand_more.svg";

const LABEL =
  "Je suis intéressé(e) par les offres et services personnalisés de l’ensemble des marques du Groupe ALTAREA pour m’accompagner dans mon projet immobilier";
const IS_REQUIRED_BY_DEFAULT = false;

export function OptInCogedimSchema() {
  return {
    optin_cogedim: optional(picklist(["0", "1"]), "0"),
  };
}

export function OptInCogedim(props: CheckboxRgpd & { showBrands?: boolean }) {
  const [local, others] = splitProps(props, ["label", "help", "required"]);

  const [brandsIsExpanded, setBrandsIsExpanded] = createSignal(false);

  return (
    <>
      <div class="optin-cogedim">
        <InputTypeCheckbox
          label={local.label || LABEL}
          help={local.help}
          required={
            local.required !== undefined
              ? local.required
              : IS_REQUIRED_BY_DEFAULT
          }
          value="1"
          {...others}
        />
      </div>
      <Show when={props.showBrands}>
        <div
          class="optin-cogedim-brands"
          classList={{ active: brandsIsExpanded() }}
        >
          <button
            type="button"
            onClick={() => setBrandsIsExpanded(!brandsIsExpanded())}
          >
            Voir les marques du Groupe Altarea{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconExpandMore />
            </i>
          </button>
          <ul>
            <li>COGEDIM – Promoteur immobilier</li>
            <li>Woodeum x Pitch – Promoteur immobilier</li>
            <li>Histoire & Patrimoine – Promoteur immobilier</li>
            <li>
              Altarea Solution Services et ses sous-marques Altarea Gestion
              Immobilière, Altarea Patrimoine et Altarea Solution Financement –
              Conseil en Patrimoine, courtage en financement, gestion locative,
              syndic de copropriété, services d’accompagnement aux investisseurs
              particuliers
            </li>
            <li>Altarea Commerce – Administration de centres commerciaux</li>
            <li>Nohée – Administration de résidences services seniors</li>
          </ul>
        </div>
      </Show>
    </>
  );
}
